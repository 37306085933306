
import React from "react"
import styled from "styled-components"
import Button from "./Button"
import Bottom from "../../data/icons/BottomBanner.svg"
import SmallBot from "../../data/icons/bluelineBot2.svg"
import BottomMain from "../../data/icons/SpaceWorms.svg"
import BottomSec from "../../data/icons/PurplePlanet.svg"

const Container = styled.div`
position:relative;
margin-top:50px;
width:100%;
min-height:300px;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
    -ms-flex-direction:column;
        flex-direction:column;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;
-webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;

@media (min-width:993px) {
    margin-top:200px;
}
`

const MobileBG = styled.div`
position:absolute;
width:100%;
height:46%;
bottom:0px;
background-color:var(--tmain);

& svg{
    position:absolute;
    bottom:98%;
    width:100%;
    right:0%;
    height:auto;
    display:block;
    z-index:-1;
}

@media (min-width:993px) {
    display:none;
}
`

const Bg = styled.div`
display:none;
position:relative;
width:100%;
@media (min-width:993px) {
    display:block;

    & svg{
        position:relative;
        bottom:-2px;
        width:100%;
        height:100%;
        display: block;
    }
}
`

const Pic1 = styled.div`
position:relative;  
width:auto;
height:auto;
width:70%;
left:0;
margin-bottom:20%;
margin-top:130px;
z-index:1;
-webkit-transform:rotateY(180deg);
        transform:rotateY(180deg);
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;


@media (min-width:993px) {
    -webkit-transform:rotateY(0deg);
            transform:rotateY(0deg);
    position:absolute;
    width:40%;
    max-width:600px;
    margin-top:0px;
    margin-bottom:3%;
    height:auto;
    left:55%;
}
@media (min-width:1800px) {
    width:600px;
    left:64%;
}
& svg {
    width:100%;
    height:100%;
}
`
const Pic2 = styled.div`
position:absolute;  
width:38%;
max-width:300px;
left:62%;
top:-15%;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;


@media (min-width:993px) {
    left:30%;
    top:-200px;
    max-width:20%;
}
& svg {
    width:100%;
    height:100%;
}
`
const Slogan = styled.div`
position:relative;
width:80%;
left:0;
bottom:0;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
    -ms-flex-flow:column wrap;
        flex-flow:column wrap;
-webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;
z-index:1;
text-align:center;

@media (min-width:993px) {
    position:absolute;
    width:50%;
    top:0px;
}
`

const SloganText = styled.div`
margin:20px 0;

& p{
    margin:0;
    font-style: normal;
    font-weight: 300;
    font-size:6vw;
    line-height: 1em;
    
    @media (min-width:600px) {
        font-size:2em;
    }
    @media (min-width:1400px) {
        font-size:3em;
    }
}

& h1{
    margin:0;
    font-style: normal;
    font-weight: bold;
    font-size:9vw;
    line-height:1em;

    @media (min-width:600px) {
        font-size:2.6em;
    }
    @media (min-width:1400px) {
        font-size:5.2em;
    }
}
`
export default function BottomBanner(props){
    let buttonHref 
    buttonHref = (props.button==null)?(buttonHref=""):(buttonHref=props.button.href)
    let buttonText
    buttonText = (props.button==null)?(buttonText=""):(buttonText=props.button.text)
    let text1 = "mit uns in die"
    let text2 = "Zukunft"
    let text3 = "wir freuen uns auf die"
    let text4 = "Zusammenarbeit"

    return(
        <Container>
            <Slogan>
                {(props.button==null)?
                (<SloganText>
                    <p>{text3}</p>
                    <h1>{text4}</h1>
                </SloganText>):
                (<SloganText>
                    <p>{text1}</p>
                    <h1>{text2}</h1>
                </SloganText>)}

                {(props.button==null)?(<div/>):(<Button backgroundcolor="var(--tmain)" color="var(--tsec)" href={buttonHref}>{buttonText}</Button>)}
            </Slogan>
            <MobileBG>
                <SmallBot/>
            </MobileBG>
            <Bg>
                <Bottom/>
            </Bg>
            
            <Pic1>
                <BottomMain/>
            </Pic1>
            <Pic2>
                <BottomSec/>
            </Pic2>
        </Container>
    )
}