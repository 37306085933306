import React from "react"
import styled from "styled-components"
import { createGlobalStyle } from 'styled-components'
import CleanBurgerMenu from "../../data/icons/CleanBurgerMenu2.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const GlobalStyle = createGlobalStyle`
  html {
    overflow: ${props => ((props.showdropdown==1) ? 'hidden' : 'visible')};
  }
`

const Container = styled.div`
width:100%;
height:64px;
background-color:var(--tmain);
color:var(--tsec);
z-index:5;
padding-bottom:0px;
@media (min-width:993px) {
    height:84px;
    padding-bottom:10px;
}
`

const NavBig = styled.div`
position:relative;
width:100%;
height:100%;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
    -ms-flex-direction:row;
        flex-direction:row;
-webkit-box-pack:end;
    -ms-flex-pack:end;
        justify-content:flex-end;

z-index:3;

@media(max-width:992px){
    display:none;
}

@media(min-width:1351px){
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center;
}
`

const LogoBig = styled(AnchorLink)`
position:absolute;
top:-20px;
left:15px;
font-size:48px;

& p{
    display:inline-block;
    font-family: Righteous;
    font-style: normal;
    font-weight: normal;
    font-size:48px;
}

& p:nth-child(1){
    color:var(--tsec);
}

& p:nth-child(2){
    margin-left:10px;
}
`

const NavBtnHorizontal = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;
margin-top:30px;
`

const NavHorizontalLink = styled(AnchorLink)`
position:relative;
text-decoration:none;
color:${props => props.btnColor};
font-size:19px;
margin: 0 50px;
font-family:Hind Guntur;

&:hover{
    &:after{
        width:100%;
    }
}

&:after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width:0;
    background-color: var(--tsec);
    border-radius:200px;

    -webkit-transition:0.15s all ease-in;

    -o-transition:0.15s all ease-in;

    transition:0.15s all ease-in;
}
`

const MenuContainer = styled.div`
position:absolute;
top:0;
right: 16px;
height:100%;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;
-webkit-user-select:none;
   -moz-user-select:none;
    -ms-user-select:none;
        user-select:none;
-webkit-transition:all 0.2s linear;
-o-transition:all 0.2s linear;
transition:all 0.2s linear;


& svg {
    height:${props => (props.showdropdown==1) ? ("50%") : ("60%")};
    width:auto;
    cursor:pointer;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
}
`

const NavSmall = styled.div`
position:relative;
width:100%;
height:100%;
background-color:${props => (props.showdropdown==1) ? ("var(--tmain)") : ("none")};

z-index:3;

@media(min-width:993px){
    display:none;
}
`

const LogoSmall = styled(AnchorLink)`
position:absolute;
top:-20px;
left:15px;
font-size:28px;

& p{
    display:inline-block;
    font-family: Righteous;
    font-style: normal;
    font-weight: normal;
}

& p:nth-child(1){
    color:var(--tsec);
}

& p:nth-child(2){
    margin-left:10px;
}
`

const NavScroll = styled.div`
position:fixed;
top: 16px;
right: 16px;
height: 56px;
width: 56px;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;
background-color: var(--tmain);
border-radius:200px;
border: 2px solid var(--tmain);
-webkit-user-select:none;
   -moz-user-select:none;
    -ms-user-select:none;
        user-select:none;
-webkit-transition: all 0.2s cubic-bezier(.47,1.64,.41,.8);
-o-transition: all 0.2s cubic-bezier(.47,1.64,.41,.8);
transition: all 0.2s cubic-bezier(.47,1.64,.41,.8);
-webkit-transform:${props => (props.atTop) ? ("scale(0)") : ("scale(1)")};
    -ms-transform:${props => (props.atTop) ? ("scale(0)") : ("scale(1)")};
    transform:${props => (props.atTop) ? ("scale(0)") : ("scale(1)")};
-webkit-box-shadow:0px 8px 16px 0px rgba(0,0,0,0.45);
        box-shadow:0px 8px 16px 0px rgba(0,0,0,0.45);

z-index:10;

& svg {
    height:${props => (props.showdropdown==1) ? ("50%") : ("60%")};
    cursor:pointer;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;
}

transition: all 0.2s;

@media(max-width:300px){
    height: 40px;
    width: 40px;
}

&:hover {
    background-color:#EEEEEE;
    & svg {
        #Top, #Bot {
            fill:var(--tmain);
        }
        #Mid {
            fill: ${props => (props.showdropdown==1)?("#00000000"):("var(--tmain)")}; 
        }
    }
}
`

const Dropdown = styled.div`
position:fixed;
top:0;
width:100vw;
height:100vh;
background-color: var(--tmain);
pointer-events:${props => (props.showdropdown==1) ? ("all") : ("none")};
-webkit-transition: all 0.45s linear;
-o-transition: all 0.45s linear;
transition: all 0.45s linear;

@media(orientation: landscape){
    clip-path:${props => (props.showdropdown==1) ? ("circle(140vw at calc(100vw - 3.8em) 2.8em)") : ("circle(0px at calc(100vw - 3.8em) 2.8em)")};
    -webkit-clip-path:${props => (props.showdropdown==1) ? ("circle(140vw at calc(100vw - 3.8em) 2.8em)") : ("circle(0px at calc(100vw - 3.8em) 2.8em)")};
}

@media(orientation: portrait){
    clip-path:${props => (props.showdropdown==1) ? ("circle(140vh at calc(100vw - 3.8em) 2.8em)") : ("circle(0px at calc(100vw - 3.8em) 2.8em)")};
    -webkit-clip-path:${props => (props.showdropdown==1) ? ("circle(140vh at calc(100vw - 3.8em) 2.8em)") : ("circle(0px at calc(100vw - 3.8em) 2.8em)")};
}

z-index:2;

& body{
    overflow: hidden;
    height: 100%;
}

overflow-y: auto;
`

const NavBtnVertical = styled.div`
width:100%;
min-height:85%;
margin-top:${props => (props.atTop) ? ("65px") : ("5%")};
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
    -ms-flex-direction:column;
        flex-direction:column;
-webkit-box-pack:space-evenly;
    -ms-flex-pack:space-evenly;
        justify-content:space-evenly;
-webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;
`

const NavVerticalLink = styled(AnchorLink)`
text-decoration:none;
color:${props => props.btnColor};
font-size:1.3em;
-webkit-transition: color 0.2s linear;
-o-transition: color 0.2s linear;
transition: color 0.2s linear;
padding:2.5% 30%;
font-family:Hind Guntur;

@media(max-width:600px){
    padding:2.5% 15%;
}

&:hover{
    color:${props => props.btnColorHover};
}
`

const CleanerBurgerMenu = styled(CleanBurgerMenu)`

#Top, #Mid, #Bot{
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transform-origin:50% 50%;
    -ms-transform-origin:50% 50%;
            transform-origin:50% 50%;
}

#Top {
    transform: ${props => (props.showdropdown==1)?("rotateZ(-45deg) translateY(80px) scaleX(1.8)"):("")};
    -webkit-transform: ${props => (props.showdropdown==1)?("rotateZ(-45deg) translateY(80px) scaleX(1.8)"):("")};
    -ms-transform: ${props => (props.showdropdown==1)?("rotateZ(-45deg) translateY(80px) scaleX(1.8)"):("")};
}
#Mid {
    fill: ${props => (props.showdropdown==1)?("#00000000"):("")};
}
#Bot {
    transform: ${props => (props.showdropdown==1)?("rotateZ(45deg) translateY(-80px) scaleX(1.8)"):("")};
    -webkit-transform: ${props => (props.showdropdown==1)?("rotateZ(45deg) translateY(-80px) scaleX(1.8)"):("")};
    -ms-transform: ${props => (props.showdropdown==1)?("rotateZ(45deg) translateY(-80px) scaleX(1.8)"):("")};
}
`

class NavBar extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            atTop: true,
            showDropdown: false,
            mobile: true
        }
    }

    componentDidMount(){
        window.addEventListener('scroll', this.checkScrollHeight)
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.checkScrollHeight)
    }

    checkScrollHeight = () => {
        if(document.documentElement.scrollTop > 65){
            this.setState({
                atTop: false
            })
        }
        else{
            this.setState({
                atTop: true
            })
        }
    }

    toggleDropdown(){
        this.setState({
            showDropdown: !this.state.showDropdown
        })
    }

    render(){

        let navBtnColor = "var(--tsec)"
        let navBtnColorHover = "var(--tquod)"

        return(
            <Container>
                <GlobalStyle showdropdown={this.state.showDropdown?(1):(0)} />
                <NavBig atTop={this.state.atTop}>
                    <LogoBig to={"/"}>
                        <p>Kudlik </p><p> Pfeifer</p>
                    </LogoBig>
                    <NavBtnHorizontal>
                    {
                        this.props.buttons.map((item, i) => (
                            <NavHorizontalLink btnColor={navBtnColor} btnColorHover={navBtnColorHover} to={item.link} key={i}> {item.text} <span/><span/></NavHorizontalLink>
                        ))
                    }
                    </NavBtnHorizontal>
                </NavBig>
                <NavSmall atTop={this.state.atTop}>
                    <LogoSmall to={"/"}>
                        <p>Kudlik </p><p> Pfeifer</p>
                    </LogoSmall>
                    <MenuContainer showdropdown={this.state.showDropdown?(1):(0)} mobile={this.state.mobile}>
                        {
                            <CleanerBurgerMenu showdropdown={this.state.showDropdown?(1):(0)} onClick={this.toggleDropdown.bind(this)}/>
                        }
                    </MenuContainer>
                </NavSmall>
                <NavScroll atTop={this.state.atTop} showdropdown={this.state.showDropdown?(1):(0)} mobile={this.state.mobile}>
                    {
                        <CleanerBurgerMenu showdropdown={this.state.showDropdown?(1):(0)} onClick={this.toggleDropdown.bind(this)}/>
                    }
                </NavScroll>
                <Dropdown onClick={this.toggleDropdown.bind(this)} showdropdown={this.state.showDropdown?(1):(0)}>
                    <NavBtnVertical>
                    {
                        this.props.buttons.map((item, i) => (
                            <NavVerticalLink btnColor={navBtnColor} btnColorHover={navBtnColorHover} key={i} to={item.link}>{item.text}</NavVerticalLink>
                        ))
                    } 
                    </NavBtnVertical>
                </Dropdown>
            </Container>
        )
    }
}

export default NavBar;