import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Container = styled(Link)`
background-color:${props => props.backgroundcolor};
color:${props => props.color};
text-decoration:none;
position:relative;
padding:0.4em 1em 0.1em 1em;
border: 2px solid ${props => props.backgroundcolor};
border-radius:50px;
font-size:1.2em;
font-weight:bold;

-webkit-transition: all 0.15s linear;
-o-transition: all 0.15s linear;
transition: all 0.15s linear;

@media(min-width:601px){
    padding:0.5em 1.3em 0.2em 1.3em;
    font-size:1.563em;
}

&:hover{
    background-color:white;
    color:${props => props.backgroundcolor};
}
`

export default function Button(props){

    let color
    color = (props.color==null) ? (color="black"):(color=props.color)
    let backgroundcolor 
    backgroundcolor = (props.backgroundcolor==null) ? (backgroundcolor="var(--tgrey)"):(backgroundcolor=props.backgroundcolor)
    let href
    href = (props.href==null)?(href=""):(href=props.href)
    return(
        <Container to={href} color={color} backgroundcolor={backgroundcolor}>
            {props.children}
        </Container>
    )
}