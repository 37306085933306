import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"


const Container = styled.div`
position:relative;
bottom:0px;
width:100%;

background-color:var(--tmain);
`


const Footnotes = styled.div`
width:100%;
padding-bottom:1em;

@media (min-width:601px) {
    padding-bottom:0;
}

& p{
    color:var(--tsec);
    text-align:center;
    text-decoration:none;
  }
`

const FootLink = styled(Link)`
color:var(--tsec);
text-align:center;
text-decoration:none;
padding: 0.25em 0;

@media (min-width:601px) {
    padding: 1em 0;
}
`

export default function Footer(){
    return(
        <Container>
            <Footnotes className={"s1-12 row"}>
                <FootLink to="/imprint/" className="l1-4 m1-12 s1-12">Impressum</FootLink>
                <p className="l5-8 m1-12 s1-12">© Kudlik & Pfeifer GbR</p>
                <FootLink to="/privacy/" className="l9-12 m1-12 s1-12">Datenschutz</FootLink>
            </Footnotes>
        </Container>
    )
}