import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import NavBar from "./NavBar"
import Footer from "./Footer"


const Container = styled.div`
`

export default function Layout(props){
    const data = useStaticQuery(graphql`
    query LayoutQuery{
        allGlobalJson {
            nodes {
              PhoneL
              PhoneF
              EmailMain
              Strasse
              Ort
              NavBar {
                buttons {
                  text
                  link
                }
              }
            }
        }
    }
    `)

    const globalNode = data.allGlobalJson.nodes[0]
    const navBar = globalNode.NavBar

    return(
        <Container id={props.id}>
            <NavBar buttons={navBar.buttons}/>
            {props.children}
            <Footer/>
        </Container>
    )
}